import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;
export default class VentaService {
	async crear(datos) {
		return await fetchWrapper.post(`${ruta}/ventas`, datos);
	}

	async getAllVentas() {
		return await fetchWrapper.get(`${ruta}/ventas`);
	}

	async getVenta(id) {
		return await fetchWrapper.get(`${ruta}/ventas/` + id);
	}

	async imprimirVenta(id) {
		return await fetchWrapper.getPdf(`${ruta}/venta/imprimir/${id}`);
	}
	async filtrarVentas(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_venta`, datos);
	}
	async imprimirPDF(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte/venta/imprimir`, datos);
	}

	async imprimirRGananciasPDF(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte_ganancias_ventas`, datos);
	}

	async exportarVentas(datos) {
		const exportado = await fetchWrapper.postWithBlob(
			`${ruta}/ventas-export`,
			datos
		);
		return exportado;
	}
  async exportarRGananciasVentas(datos) {
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/ganancias_ventas_export`,
      datos
    );
    return exportado;
  }

	async filtrarGananciasVentas(datos) {
		return await fetchWrapper.post(`${ruta}/filtros_ganancias_venta`, datos);
	}

  async anularVenta(id) {
    return await fetchWrapper.put(`${ruta}/ventas/anular/${id}`);
  }

	/* ====================================
	RUTAS PARA FACTURACION ELECTRONICA 
	========================================*/

	async filtros_facturas(datos) { 
		return await fetchWrapper.post(`${ruta}/filtros_factura`, datos);
	}
	async exportarFacturas(datos) {
		const exportado = await fetchWrapper.postWithBlob( 
			`${ruta}/facturas-export`,
			datos
		);
		return exportado;
	}
	async imprimirPDFFacturas(datos) {
		return await fetchWrapper.postPdf(`${ruta}/reporte/factura/imprimir`, datos);
	}
}
