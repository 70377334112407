<template>
	<ConfirmDialog></ConfirmDialog>
	<Toast />
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3 active">Detalle Ventas</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Ventas</div>
				<div class="flex align-items-center text-700 flex-wrap">
					<div class="mr-5 flex align-items-center mt-3">
						<i class="pi pi-box mr-2"></i>
						<span>{{ totalRegistros }} Ventas</span>
					</div>
				</div>
			</div>
			<div class="mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'Venta Crear' in auth.user.permissions"
					class="p-button-outlined mr-2"
					icon="pi pi-plus"
					v-tooltip.top="'Nueva Venta'"
					@click="nuevaVenta"
				></Button>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="ventas"
				:value="ventas"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:filters="buscar"
				:loading="cargando"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 25]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas"
				responsiveLayout="scroll"
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar..."
							/>
						</span>
					</div>
				</template>
				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
				<Column field="id" header="CÓDIGO VENTA" :sortable="true"> </Column>
        <Column field="nombre_usuario_caja" header="VENDEDOR"> 
        </Column>
				<Column field="razon_social" header="RAZÓN SOCIAL"> </Column>
				<Column field="fecha_venta" header="FECHA VENTA"> </Column>
				<Column field="nit" header="NIT/CI"> </Column>
				<Column field="descuento" header="DESCUENTO" class="text-right"> </Column>
				<Column field="total_cancelar" header="TOTAL VENTA" class="text-right"> </Column>
				<Column field="estado" header="ESTADO">
					<template #body="{ data }">
						<span :class="'product-badge ' + claseEstado[data.estado]">
							{{ data.estado_texto }}
						</span>
					</template>
				</Column>
				<Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							icon="pi pi-ellipsis-v"
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						></Button>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						></Menu>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import { useAuth } from "@/stores";
export default {
	data() {
		return {
			ventas: [],
			totalRegistros: 0,
			cargando: false,
			buscar: {
				global: { matchMode: FilterMatchMode.CONTAINS, value: null },
			},
			claseEstado: ["status-outofstock", "status-instock", "status-lowstock"],
		};
	},
	ventaService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.ventaService = new VentaService();
	},
	mounted() {
		this.cargarVentas();
	},
	methods: {
		acciones(datos) {
			return [
				{
					label: "Ver Detalle",
					icon: "pi pi-fw pi-eye",
					disabled:
						"Venta Ver Detalle" in this.auth.user.permissions ? false : true,
					to: `/ventas/${datos.id}/detalle`,
				},
				{
					label: "Descargar PDF",
					disabled:
						"Venta DescargarPDF" in this.auth.user.permissions ? false : true,
					icon: "pi pi-fw pi-file-pdf",
					command: () => this.imprimirVenta(datos.id),
				},
				{
					label: "Anular Venta",
					icon: "pi pi-fw pi-trash",
					disabled: datos.estado == 0 ? true : false || "Venta Anular" in this.auth.user.permissions ? false : true,
					command: () => this.anularVenta(datos.id),
				},
			];
		},
		anularVenta(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea anular la venta?",
				header: "Anular Venta",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo Anular",
				rejectLabel: "No, Cancelar",
				accept: () => {
					this.ventaService.anularVenta(id).then((response) => {
						this.$toast.add({
							severity: "success",
							summary: "Venta Anulada",
							detail: response.mensaje,
							life: 3000,
						});
						this.cargarVentas();
					});
				},
			});
		},
		nuevaVenta() {
			this.$router.push({ name: "nueva_venta" });
		},
		cargarVentas() {
			this.cargando = true;
			this.ventaService.getAllVentas().then((response) => {
				this.ventas = response.ventas;
				this.cargando = false;
			});
		},
		imprimirVenta(id) {
			this.ventaService
				.imprimirVenta(id)
				.then((data) => {
					console.log(data);
				})
				.catch((error) => console.log(error));
		},
	},
	watch: {
		ventas() {
			this.totalRegistros = this.ventas.length;
		},
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
